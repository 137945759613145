import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";


const FinancialTracker = () => {

    const filters = { "tracker_type": ["finance"] }

    const { user } = useUser();

    if (!user) {
        return (
            <div>
                <h1 style={{ textAlign: 'center' }}>Financial News Tracker</h1>
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                    Sign in to VerbaAI to access additional features, including bookmarking
                    speeches and creating custom trackers.
                </Alert>
                <SearchableTable baseRequest={filters} />
            </div>
        );
    }

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Financial News Tracker</h1>
            <SearchableTable baseRequest={filters} />
        </div>
    );
};

export default FinancialTracker;
