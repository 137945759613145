import React, { useRef } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { getDatePublished, getTimePublished, getTimezoneName } from '../search/DataTableCluster';
import zoomPlugin from 'chartjs-plugin-zoom';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    annotationPlugin,
    zoomPlugin
);

const ClusterSentiment = ({ articleData, clusterTimestamps, mapSentiment }) => {

    const chartRef = useRef(null);

    const prepareChartDataArticleSentiments = () => {
        const dataPoints = [];

        articleData.forEach(article => {
            const sentiment = mapSentiment(article.sentiment_compound);
            dataPoints.push({
                x: new Date(article.date_time_published_utc), // Ensure dates are correctly parsed
                y: article.sentiment_compound, // Use the sentiment_compound field for the y-axis
                title: article.title, // Include the article title
                source: article.source,
                country: article.country,
                language: article.language,
                article_id: article.article_id,
                backgroundColor: sentiment.color,
                borderColor: "#222222"
            });
        });

        return dataPoints;
    };

    const sentimentChartData = {
        datasets: [{
            label: 'Timestamp',
            data: prepareChartDataArticleSentiments(),
            backgroundColor: function (context) {
                return context.raw.backgroundColor;
            },
            borderColor: 'black',
            borderWidth: 1,
            pointRadius: 7, // Set the radius of the data points
            pointHoverRadius: 9 // Set the radius of the data points on hover
        }]
    };

    const sentimentChartOptions = {
        scales: {
            x: {
                type: 'time',
                xMin: 0,
                title: {
                    display: true,
                    text: 'Publication Time',
                    color: 'black',
                    font: {
                        family: 'Afacad',
                        size: 24,
                        weight: 500,
                    }
                },
                ticks: {
                    font: {
                        family: 'Afacad',
                        size: 16,
                    },
                    color: 'black',
                    autoSkip: true,
                    maxTicksLimit: 8,
                    maxRotation: 90,
                    minRotation: 0,
                    padding: 10
                },
            },
            y: {
                beginAtZero: true,
                min: -1,
                max: 1,
                grace: '5%',
                title: {
                    display: true,
                    text: 'Sentiment',
                    color: 'black',
                    font: {
                        family: 'Afacad',
                        size: 24,
                        weight: 500,
                    }
                },
                ticks: {
                    font: {
                        family: 'Afacad',
                        size: 16,
                    },
                    padding: 10,
                    color: 'black',
                    stepSize: 0.2,
                    callback: function (value) {
                        if (value === -0.6 || value === -0.2 || value === 0.2 || value === 0.6 || value === 1 || value === -1) {
                            return value;
                        }
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top'
            },
            tooltip: {
                mode: 'index',
                intersect: true,
                callbacks: {
                    title: function (context) {
                        const dataPoint = context[0].raw;
                        return dataPoint.title;
                    },
                    label: function (context) {
                        const dataPoint = context.raw;
                        const sentiment = mapSentiment(dataPoint.y);
                        return [
                            `${sentiment.text} (${dataPoint.y})`,
                            `${dataPoint.source}  •  ${dataPoint.country}  •  ${dataPoint.language}`,
                            `${getDatePublished(dataPoint.x)}, ${getTimePublished(dataPoint.x)} (${getTimezoneName(dataPoint.y)})`
                        ];
                    }
                },
                titleFont: {
                    family: 'Afacad',
                    size: 20,
                    weight: '500'
                },
                bodyFont: {
                    family: 'Afacad',
                    size: 18,
                }
            },
            annotation: {
                annotations: clusterTimestamps.map(timestamp => ({
                    type: 'line',
                    xMin: new Date(timestamp),
                    xMax: new Date(timestamp),
                    borderColor: 'black',
                    borderWidth: 2,
                    borderDash: [5, 5],
                    label: {
                        content: 'Cluster Timestamp',
                        enabled: true,
                        position: 'top',
                        backgroundColor: 'rgba(75, 192, 192, 0.8)',
                        color: '#000'
                    }
                })),
            },
            zoom: {
                zoom: {
                    drag: {
                        enabled: true, // Enable drag-to-zoom
                        borderColor: 'rgb(180, 180, 180, 0.8)',
                        backgroundColor: 'rgb(180, 180, 180, 0.15)',
                        borderWidth: 1, // Drag rectangle border width
                    },
                    mode: 'x',
                },
            }
        },
        responsive: true,
        maintainAspectRatio: true,
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const element = elements[0];
                const dataIndex = element.index;
                const dataPoint = sentimentChartData.datasets[0].data[dataIndex];
                window.open(`https://verbaai.org/article/${dataPoint.article_id}`, '_blank');
            }
        }
    };


    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    return (
        <div className="chart-container">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontWeight: '500', marginLeft: '6%' }}>
                    - - - Narrative Resummarized
                </div>
                <div style={{ textAlign: 'right' }}>
                    <button className='btn-sidebar btn-charcoal-gray' onClick={handleResetZoom}>
                        Reset
                    </button>
                </div>
            </div>
            <Scatter ref={chartRef} data={sentimentChartData} options={sentimentChartOptions} />
        </div>
    );
    
};

export default ClusterSentiment;
