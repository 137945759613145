import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import { Tabs, Tab, Alert } from 'react-bootstrap';



const SquadraTracker = () => {

    const filters = {
        "tracker_type": ["squadra"],
    }

    const { user } = useUser();

    const squadraOrgDict = {
        "Shift5": "Shift5",
        "Primordial Labs": "Primordial Labs",
        "Overwatch Imaging": "Overwatch Imaging",
        "NetRise": "NetRise",
        "Falcomm": "Falcomm",
        "Datalogz": "Datalogz",
        "Tidal Cyber": "Tidal Cyber",
        "Prewitt Ridge": "Prewitt Ridge",
        "Sicura": "Sicura",
        "Resupply": "Resupply",
        "Privva": "Privva",
        "Instant Teams": "Instant Teams",
        "VirgilHR": "VirgilHR",
        "Pinkaloo": "Pinkaloo",
        "Care Advisors": "Care Advisors"
    }

    const customOrgOptions = [
        { label: 'Shift5', value: 'Shift5' },
        { label: 'Primordial Labs', value: 'Primordial Labs' },
        { label: 'Overwatch Imaging', value: 'Overwatch Imaging' },
        { label: 'NetRise', value: 'NetRise' },
        { label: 'Falcomm', value: 'Falcomm' },
        { label: 'Datalogz', value: 'Datalogz' },
        { label: 'Tidal Cyber', value: 'Tidal Cyber' },
        { label: 'Prewitt Ridge', value: 'Prewitt Ridge' },
        { label: 'Sicura', value: 'Sicura' },
        { label: 'Resupply', value: 'Resupply' },
        { label: 'Privva', value: 'Privva' },
        { label: 'Instant Teams', value: 'Instant Teams' },
        { label: 'VirgilHR', value: 'VirgilHR' },
        { label: 'Pinkaloo', value: 'Pinkaloo' },
        { label: 'Care Advisors', value: 'Care Advisors' }
    ];

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Squadra Ventures Portfolio</h1>
            <Tabs defaultActiveKey="mentions" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="mentions" title="Media Mentions">
                    <SearchableTable baseRequest={filters} mediaMonitor={true} orgsOfInterest={squadraOrgDict} showOrgs={true} orgOptions={customOrgOptions} excludePast24Hours={true} />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br/>
                    <h4 style={{ textAlign: 'center' }}>
                        This tracker monitors 230,000+ outlets in real-time for mentions of Squadra Ventures portfolio companies.
                    </h4>
                </Tab>
            </Tabs>
        </div>
    );
};

export default SquadraTracker;
