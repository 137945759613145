import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import DataTableLWV from '../search/DataTableLWV.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

export function getDatePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    return `${utcDate.getMonth() + 1}`.padStart(2, '0') +
        `/${utcDate.getDate()}`.padStart(2, '0') +
        `/${utcDate.getFullYear()}`;
}

export function getTimePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    let hours = utcDate.getHours();
    let minutes = utcDate.getMinutes();

    // Round minutes to the nearest 10, and adjust hours if necessary
    minutes = Math.ceil(minutes / 10) * 10;
    if (minutes === 60) {
        minutes = 0;
        hours++;
    }

    // If hours reach 24, set it back to 23 and minutes to 50
    if (hours === 24) {
        hours = 23;
        minutes = 50;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

const ElectionFactsUT = () => {

    const rumorFilters = {
        "state": "IL"
    }

    const filters = {
        "sources": [
            "AP News - apnews.com",
            "Snopes - snopes.com",
            "FactCheck.org - factcheck.org",
            "PolitiFact - politifact.com",
            "USA Today - usatoday.com",
            "Washington Post - washingtonpost.com"
        ],
        "tracker_type": ["fact_check"],
        "topics": ["Politics"],
        "locations": ["United States"],
    };


    const illinoisNewsFilters = {
        "languages": ["English"],
        "sources": [
            "capitolnewsillinois.com",
            "chicagotribune.com",
            "chicago.suntimes.com",
            "dailyherald.com",
            "wbez.org",
            "sj-r.com",
            "news-gazette.com",
            "rrstar.com",
            "thesouthern.com",
            "herald-review.com",
            "pantagraph.com",
            "politico.com",
            "axios.com"
        ],
        "topics": ["Politics"],
        "article_leaning": ["Neither"],

    }

    const illinoisNewsSourceOptions = [
        { label: 'Capitol News Illinois', value: 'capitolnewsillinois.com' },
        { label: 'Chicago Tribune', value: 'chicagotribune.com' },
        { label: 'Chicago Sun-Times', value: 'chicago.suntimes.com' },
        { label: 'Daily Herald', value: 'dailyherald.com' },
        { label: 'WBEZ Chicago', value: 'wbez.org' },
        { label: 'The State Journal-Register', value: 'sj-r.com' },
        { label: 'The News-Gazette', value: 'news-gazette.com' },
        { label: 'Rockford Register Star', value: 'rrstar.com' },
        { label: 'The Southern Illinoisan', value: 'thesouthern.com' },
        { label: 'Herald & Review', value: 'herald-review.com' },
        { label: 'The Pantagraph', value: 'pantagraph.com' },
        { label: 'Politico', value: 'politico.com' },
        { label: 'Axios', value: 'axios.com' }
    ];

    const { user } = useUser();

    // Check if isMobile
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const customSourceOptions = [
        { label: 'AP News - apnews.com', value: 'apnews.com' },
        { label: 'FactCheck - factcheck.org', value: 'factcheck.org' },
        { label: 'PolitiFact - politifact.com', value: 'politifact.com' },
        { label: 'Snopes - snopes.com', value: 'snopes.com' },
        { label: 'USA Today - usatoday.com', value: 'usatoday.com' },
        { label: 'Washington Post - washingtonpost.com', value: 'washingtonpost.com' }
    ];


    const [rumorRadarData, setRumorRadarData] = useState([]); //tableData will always be displayed in the data table; setTableData() needs to be called by handleSearchClick

    useEffect(() => {
        fetchRumorRadarData();
    }, []); // This will call fetchData on component mount


    const fetchRumorRadarData = async () => {
        try {
            const response = await axios.post('https://fopotracking.ue.r.appspot.com/lwv-search', rumorFilters);
            // const response = await axios.post('http://localhost:5000/lwv-search', filters);

            let records = [];

            response['data'].forEach(item => {
                const { date_published, link, word_count, title, source_url, country, language, website_name, country_rank, site_rank, topics, locations, organizations, persons, summary } = item['source'];
                const uniqueId = item['id']; // Adjust this according to your response structure

                records.push({
                    "date_published": item['source']['date_published'],
                    "misleading_claim": item['source']['misleading_claim'],
                    "reference": item['source']['reference'],
                    "fact_check": item['source']['fact_check']
                });
            });
            // setRumorRadarData(records);
            // console.log("records are", records);
        }
        catch (error) {
            console.error('Error fetching latest data:', error);
        }
    };

    const renderMobilePage = () => (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <a href="https://www.lwvil.org/">
                    <img src="/LWVIL_Logo.png" alt="LWVIL_Logo" style={{ maxWidth: '90%', height: 'auto' }} />
                </a>
            </div>
            <br />
            <h1 style={{ textAlign: 'center' }}>2024 U.S. Elections: The Facts</h1>
            <h2 style={{ textAlign: 'center' }}>Curated from Non-Partisan Orgs and Major Outlets</h2>
            {!user && (
                <div>
                    <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                        Sign in to VerbaAI to access additional features, including bookmarking
                        speeches and creating custom trackers.
                    </Alert>
                </div>
            )}
            <Tabs defaultActiveKey="fact-check-feed" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="fact-check-feed" title="Fact Check Feed">
                    <SearchableTable electionFactsTracker={true} baseRequest={filters} showCluster={false} countrySearch={false} countryColumn={false} languageSearch={false} languageColumn={false} sourceOptions={customSourceOptions} excludePast24Hours={true} preset_query={'election'} />
                </Tab>
                <Tab eventKey="illinois-news" title="Illinois News">
                    <SearchableTable showCharged={true} showCluster={false} baseRequest={illinoisNewsFilters} countrySearch={false} countryColumn={false} languageSearch={false} languageColumn={false} sourceOptions={illinoisNewsSourceOptions} preset_query={'Illinois'} />
                </Tab>
                <Tab eventKey="rumor-radar" title="Rumor Radar">
                    <div className="searchable-container">
                        <div className="data-table">
                            <DataTableLWV data={rumorRadarData} />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        The spread of false information during elections poses a critical threat to U.S. democracy. These falsehoods can rapidly proliferate online, misleading voters about candidates and policies, distorting democratic dialogue, and eroding the public's trust in the very institutions meant to safeguard their voices.
                        <br /><br />
                        VerbaAI has partnered with the <Link to="https://www.lwvil.org/">League of Women Voters of Illinois</Link> to launch Election Facts, a non-partisan, one-stop-shop for fact-checking U.S. election news. Election Facts has two key components.
                        <br /><br />
                        <b><i>Fact Check Feed:</i></b> We monitor relevant reports from leading fact-checking organizations that investigate the accuracy of political stories. The work of these fact-checking organizations is crucial, and by consolidating their research into one place, we hope to make fact-checking political news easier than ever.
                        <br /><br />
                        <b><i>Illinois News:</i></b> We monitor reputable state and national outlets for Illinois news.
                        <br /><br />
                        <b><i>Rumor Radar:</i></b> We publish fact-checks by members of the LWV-IL on misleading narratives about the elections.
                        <br /><br />
                        Election Facts Illinois features real-time news monitoring, AI summaries for key insights, and comprehensive search filters.
                        Check out our <Link to="/userguide">User Guide</Link> for more information on each of these features. And, check out our other <Link to="/issue-trackers">Issue Trackers</Link> to see how VerbaAI is making it easier than ever to stay informed online.
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );


    const renderDesktopPage = () => (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href="https://www.lwvil.org/">
                        <img src="/LWVIL_Logo.png" alt="LWVIL_Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                    </a>
                </div>
                <div style={{ flex: 2, textAlign: 'center' }}>
                    <h1>2024 U.S. Election News: The Facts</h1>
                    <h2>Curated from Non-Partisan Orgs and Major Outlets</h2>
                </div>
                <div style={{ flex: 1 }}>
                </div>
            </div>
            <div>
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                    Does your organization want a custom VerbaAI tracker, tailored to your needs? Contact us at info@verbaai.org!
                </Alert>
            </div>
            <Tabs defaultActiveKey="fact-check-feed" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="fact-check-feed" title="Fact Check Feed">
                    <SearchableTable electionFactsTracker={true} baseRequest={filters} showCluster={false} countrySearch={false} countryColumn={false} languageSearch={false} languageColumn={false} sourceOptions={customSourceOptions} excludePast24Hours={true} preset_query={'election'} />
                </Tab>
                <Tab eventKey="illinois-news" title="Illinois News">
                    <SearchableTable showCharged={true} showCluster={false} baseRequest={illinoisNewsFilters} countrySearch={false} countryColumn={false} languageSearch={false} languageColumn={false} sourceOptions={illinoisNewsSourceOptions} preset_query={'Illinois'} />
                </Tab>
                <Tab eventKey="rumor-radar" title="Rumor Radar">
                    <DataTableLWV data={rumorRadarData} />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        The spread of false information during elections poses a critical threat to U.S. democracy. These falsehoods can rapidly proliferate online, misleading voters about candidates and policies, distorting democratic dialogue, and eroding the public's trust in the very institutions meant to safeguard their voices.
                        <br /><br />
                        VerbaAI has partnered with the <Link to="https://www.lwvil.org/">League of Women Voters of Illinois</Link> to launch Election Facts Illinois, a non-partisan, one-stop-shop for fact-checking U.S. election news. Election Facts Illinois has three key components.
                        <br /><br />
                        <b><i>Fact Check Feed:</i></b> We monitor relevant reports from leading fact-checking organizations that investigate the accuracy of political stories. The work of these fact-checking organizations is crucial, and by consolidating their research into one place, we hope to make fact-checking political news easier than ever.
                        <br /><br />
                        <b><i>Illinois News:</i></b> We monitor reputable state and national outlets for Illinois news.
                        <br /><br />
                        <b><i>Rumor Radar:</i></b> We publish fact-checks by members of the LWV-IL on misleading narratives about the elections.
                        <br /><br />
                        Election Facts Illinois features real-time news monitoring, AI summaries for key insights, and comprehensive search filters.
                        Check out our <Link to="/userguide">User Guide</Link> for more information on each of these features. And, check out our other <Link to="/issue-trackers">Issue Trackers</Link> to see how VerbaAI is making it easier than ever to stay informed online.
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );

    return (
        <div>
            {isMobile ? renderMobilePage() : renderDesktopPage()}
        </div>
    );

};

export default ElectionFactsUT;
