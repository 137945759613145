import React, { useRef, useState, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin,
    zoomPlugin
);

// Function to generate random colors
const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const ClusterSimilarity = ({ articleTsne, clusterTsne, articleData }) => {
    const chartRef = useRef(null);

    // Create a color mapping for each source
    const sourceColors = {};
    const sourceCount = {};

    articleData.forEach(article => {
        if (!sourceColors[article.source]) {
            sourceColors[article.source] = getRandomColor();
        }
        if (!sourceCount[article.source]) {
            sourceCount[article.source] = 0;
        }
        sourceCount[article.source]++;
    });

    // Get the top 5 most common sources
    const topSources = Object.entries(sourceCount)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(entry => entry[0]);

    const prepareChartDataArticleSentiments = () => {
        const dataPoints = [];

        articleData.forEach(article => {
            const embedding = articleTsne[article.article_id];
            if (embedding) {
                dataPoints.push({
                    x: parseFloat(embedding[0].toFixed(2)),
                    y: parseFloat(embedding[1].toFixed(2)),
                    title: article.title,
                    source: article.source,
                    country: article.country,
                    language: article.language,
                    article_id: article.article_id,
                    backgroundColor: sourceColors[article.source],
                    borderColor: "#222222"
                });
            }
        });

        return dataPoints;
    };

    // Calculate min and max for x and y axes

    const dataPoints = prepareChartDataArticleSentiments();
    const xValues = dataPoints.map(point => point.x);
    const yValues = dataPoints.map(point => point.y);

    const minX = Math.min(...xValues, -20);
    const maxX = Math.max(...xValues, 20);
    const minY = Math.min(...yValues, -20);
    const maxY = Math.max(...yValues, 20);

    const minRange = Math.min(minX, minY) - 10;
    const maxRange = Math.max(maxX, maxY) + 10;


    const sentimentChartData = {
        datasets: [
            {
                label: 'Articles',
                data: prepareChartDataArticleSentiments(),
                backgroundColor: function (context) {
                    return context.raw.backgroundColor;
                },
                borderColor: 'black',
                borderWidth: 1,
                pointRadius: 7,
                pointHoverRadius: 9
            },
            // {
            //     label: 'Cluster Average',
            //     data: [{
            //         x: clusterTsne[0],
            //         y: clusterTsne[1],
            //         title: 'Average of All Articles',
            //         backgroundColor: 'red',
            //         borderColor: 'black'
            //     }],
            //     backgroundColor: 'red',
            //     borderColor: 'black',
            //     borderWidth: 1,
            //     pointRadius: 14, // Double the size of other points
            //     pointHoverRadius: 18 // Double the hover size of other points
            // }
        ]
    };

    const sentimentChartOptions = {
        scales: {
            x: {
                type: 'linear',
                min: minRange, // Set the dynamic minimum value for x-axis
                max: maxRange, // Set the dynamic maximum value for x-axis
                ticks: {
                    display: false
                },
                grid: {
                    display: true
                },
                title: {
                    display: true,
                    text: 't-SNE 1',
                    color: 'black',
                    font: {
                        family: 'Afacad',
                        size: 24,
                        weight: 500,
                    }
                },
                ticks: {
                    font: {
                        family: 'Afacad',
                        size: 16,
                    },
                    color: 'black',
                    maxTicksLimit: 8,
                    maxRotation: 90,
                    minRotation: 0,
                    padding: 10
                }
            },
            y: {
                type: 'linear',
                min: minRange, // Set the dynamic minimum value for y-axis
                max: maxRange, // Set the dynamic maximum value for y-axis
                ticks: {
                    display: false
                },
                grid: {
                    display: true
                },
                title: {
                    display: true,
                    text: 't-SNE 2',
                    color: 'black',
                    font: {
                        family: 'Afacad',
                        size: 24,
                        weight: 500,
                    }
                },
                ticks: {
                    font: {
                        family: 'Afacad',
                        size: 16,
                    },
                    color: 'black',
                    maxTicksLimit: 8,
                    maxRotation: 90,
                    minRotation: 0,
                    padding: 10
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    generateLabels: (chart) => {
                        return topSources.map((source) => {
                            return {
                                text: source,
                                fillStyle: sourceColors[source],
                                strokeStyle: '#222222',
                                lineWidth: 2,
                                hidden: false
                            };
                        });
                    }
                },
                font: {
                    family: 'Afacad'
                }
            },
            tooltip: {
                mode: 'index',
                intersect: true,
                callbacks: {
                    title: function (context) {
                        const dataPoint = context[0].raw;
                        return dataPoint.title;
                    },
                    label: function (context) {
                        const dataPoint = context.raw;
                        if (dataPoint.title === 'Average of All Articles') {
                            return ['Average of All Articles'];
                        }
                        return [
                            `Source: ${dataPoint.source}`,
                            `Country: ${dataPoint.country}`,
                            `Language: ${dataPoint.language}`,
                            `t-SNE: (${dataPoint.x}, ${dataPoint.y})`,
                        ];
                    }
                },
                titleFont: {
                    family: 'Afacad',
                    size: 20,
                    weight: '500'
                },
                bodyFont: {
                    family: 'Afacad',
                    size: 18,
                }
            },
            zoom: {
                zoom: {
                    drag: {
                        enabled: true,
                        borderColor: 'rgb(180, 180, 180, 0.8)',
                        backgroundColor: 'rgb(180, 180, 180, 0.15)',
                        borderWidth: 1,
                    },
                    mode: 'xy',
                },
            }
        },
        responsive: true,
        maintainAspectRatio: true,
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const element = elements[0];
                const dataIndex = element.index;
                const dataPoint = sentimentChartData.datasets[element.datasetIndex].data[dataIndex];
                if (dataPoint.article_id) {
                    window.open(`https://verbaai.org/article/${dataPoint.article_id}`, '_blank');
                }
            }
        }
    };

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    return (
        <div className="chart-container">
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <button className='btn-sidebar btn-charcoal-gray' onClick={handleResetZoom}>
                    Reset
                </button>
            </div>
            <Scatter ref={chartRef} data={sentimentChartData} options={sentimentChartOptions} />
        </div>
    );
};

export default ClusterSimilarity;
